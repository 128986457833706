import type { FC } from 'react';
import React, { Fragment, useState, useEffect } from 'react';
import { styled, css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N400, N500 } from '@atlaskit/theme/colors';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { WebItemList } from '@confluence/web-item-list';
import type { ForgeUIContentBylineItemExtensionType } from '@confluence/forge-ui';
import {
	confluenceLocalStorageInstance as localStorage,
	keys as localStorageKeys,
} from '@confluence/storage-manager';

import { ByLineForgeApp } from './ByLineForgeApp';
import { ByLineAppsShowMoreLessToggle } from './ByLineAppsShowMoreLessToggle';

const NUMBER_OF_VISIBLE_APPS = 3;
const LIVE_PAGES_NUMBER_OF_VISIBLE_APPS = 4;

// eslint-disable-next-line @compiled/no-exported-css, @compiled/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
export const dot = css`
	content: '•';
	display: inline-block;
	color: ${token('color.text.subtle', 'grey')};
	padding: 0 ${token('space.100', '8px')} 0 0;
	text-decoration: none;
	/* for IE <= 11 */
`;

// eslint-disable-next-line @compiled/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const StyledWebItemList = styled(WebItemList)<typeof WebItemList>`
	padding: 0;
	margin: 0 ${token('space.050', '4px')} 0 0;

	& .bylineWebItem,
	& .bylineWebItem:visited {
		margin: 0;
		display: inline-block;
		color: ${token('color.text.subtle', N500)};
	}

	& .bylineWebItem img {
		vertical-align: text-bottom;
	}

	& .bylineWebItem.with-dot:before {
		${dot};
	}

	& .bylineWebItem:hover {
		color: ${token('color.text.subtlest', N400)};
	}

	& .bylineWebItem:hover:before {
		/* for IE <= 11 */
		text-decoration: none;
		color: ${token('color.text.subtlest', N400)};
	}
`;

type ByLineAppsComponentProps = {
	contentId: string;
	connectItems: object[];
	forgeItems: ReadonlyArray<ForgeUIContentBylineItemExtensionType>;
	onLoad: () => void;
	showLivePagesExtensionPoints?: boolean;
	showLoader?: boolean;
};

export const ByLineAppsComponent: FC<ByLineAppsComponentProps> = ({
	contentId,
	connectItems,
	forgeItems,
	onLoad,
	showLivePagesExtensionPoints,
	showLoader,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const numberOfVisibleApps = showLivePagesExtensionPoints
		? LIVE_PAGES_NUMBER_OF_VISIBLE_APPS
		: NUMBER_OF_VISIBLE_APPS;
	const [isShowAll, setShowAll] = useState(
		localStorage.getItemAsBoolean(localStorageKeys.BYLINE_APPS_SHOW_ALL),
	);

	useEffect(() => {
		localStorage.setItem(localStorageKeys.BYLINE_APPS_SHOW_ALL, isShowAll);
	}, [isShowAll]);

	useEffect(() => {
		onLoad();
	}, [onLoad]);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'rendered',
				actionSubject: 'bylineApps',
				source: 'ByLineAppsComponent',
				attributes: {
					numberOfApps: connectItems?.length + forgeItems?.length,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, connectItems?.length, forgeItems?.length]);

	if (showLoader) {
		// Doing this to prevent flash during live pages SSR hydration step.
		// At this point, the data has been loaded but showLoader is still true since the onLoad is asynchronously setting the showLoader
		// state to false. It will briefly render both the placeholder and the actual content at the same time.
		// Returning null here ensures we only show actual content when the loader is not showing and prevents the flash.
		return null;
	}

	connectItems = connectItems || [];
	forgeItems = forgeItems || [];

	const renderPart = (cApps, fApps, numberOfForgeAppsToShow) => (
		<Fragment>
			{cApps.length ? (
				<StyledWebItemList
					pageId={contentId}
					webItems={cApps}
					ContainerTagName="span"
					webItemClassName="bylineWebItem"
					hideDot={showLivePagesExtensionPoints}
				/>
			) : null}
			{fApps.map((app, idx) => (
				<ByLineForgeApp
					key={app.id}
					app={app}
					contentId={contentId}
					isHidden={isShowAll ? false : idx >= numberOfForgeAppsToShow}
					setShowAll={setShowAll}
					// Not showing dot style for live page extension points byline apps - they will have a margin space separator instead
					hideDot={showLivePagesExtensionPoints}
				/>
			))}
		</Fragment>
	);

	if (connectItems.length + forgeItems.length <= numberOfVisibleApps) {
		return renderPart(connectItems, forgeItems, forgeItems.length);
	} else {
		// We should show the first numberOfVisibleApps apps including Connect and Forge app;
		// however, all Forge apps need to be RENDERED in order for the Forge Keyboard Shortcut listener
		// to be rendered and active. If (Connect app + Forge apps) is greater than numberOfVisibleApps,
		// then we will show the first numberOfVisibleApps Connect and Forge apps, render all the
		// Forge apps, but visually hide the Forge apps that go beyond numberOfVisibleApps.
		const numberOfConnectAppsToRender = Math.min(connectItems.length, numberOfVisibleApps);
		const numberOfForgeAppsToShow = numberOfVisibleApps - numberOfConnectAppsToRender;

		const firstConnectApps = connectItems.slice(0, numberOfConnectAppsToRender);
		const remainingConnectApps = connectItems.slice(numberOfConnectAppsToRender);

		return (
			<Fragment>
				{renderPart(firstConnectApps, forgeItems, numberOfForgeAppsToShow)}
				{isShowAll ? (
					<Fragment>
						{renderPart(remainingConnectApps, [], 0)}
						<ByLineAppsShowMoreLessToggle
							showMore={false}
							showButtonDesign={!!showLivePagesExtensionPoints}
							setShowAll={setShowAll}
						/>
					</Fragment>
				) : (
					<ByLineAppsShowMoreLessToggle
						showMore
						showButtonDesign={!!showLivePagesExtensionPoints}
						setShowAll={setShowAll}
					/>
				)}
			</Fragment>
		);
	}
};
