import type { FC } from 'react';
import React from 'react';
import { css } from '@compiled/react';

import { Box, xcss } from '@atlaskit/primitives';

import type { ForgeUIContentBylineItemExtensionType } from '@confluence/forge-ui';

const containerStyles = css({
	display: 'inline-flex',
});

const hiddenStyles = xcss({
	opacity: 0,
});

const dynamicLineStyles = xcss({
	backgroundColor: 'color.skeleton',
	height: '12px',
	borderRadius: 'border.radius',
	display: 'inline-block',
	marginRight: 'space.250',
});

const forgeLineStyles = xcss({
	width: '62px', // px taken from original placeholder
});

export const LivePagesByLineAppsPlaceholder: FC<{
	connectItems: any[];
	forgeItems: ReadonlyArray<ForgeUIContentBylineItemExtensionType>;
}> = ({ connectItems, forgeItems }) => {
	return (
		<div css={containerStyles} data-testid="live-pages-byline-apps-loading">
			{[...connectItems, ...forgeItems].map((item, index) => {
				// Forge items have dynamic titles that are unknown and based on app - just using a generic size for their placeholder
				const isForgeItem = !!item?.properties?.title;
				return (
					<Box
						xcss={isForgeItem ? [dynamicLineStyles, forgeLineStyles] : dynamicLineStyles}
						key={index}
						testId={isForgeItem ? 'forge-placeholder' : 'connect-placeholder'}
					>
						<Box xcss={hiddenStyles}>{item?.label}</Box>
					</Box>
				);
			})}
		</div>
	);
};
