import React, { memo, type FC } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import { xcss, Flex } from '@atlaskit/primitives';
import ClockIcon from '@atlaskit/icon/core/clock';
import { token } from '@atlaskit/tokens';
import { N500 } from '@atlaskit/theme/colors';

import type { ByLineLastUpdatedQuery_content_nodes_version as ByLineLastUpdatedVersion } from './__types__/ByLineLastUpdatedQuery';

type ByLineLastUpdatedCompactProps = {
	version: ByLineLastUpdatedVersion;
	loading?: boolean;
};

const loadingStyle = xcss({
	width: '85px',
	backgroundColor: 'color.skeleton.subtle',
	height: '24px',
	borderRadius: '20px',
});

const lastUpdateStyles = xcss({
	marginTop: 'space.025',
	marginLeft: 'space.100',
	marginRight: 'space.150',
});

const i18n = defineMessages({
	lastUpdateIconLabel: {
		id: 'content-header.by-line.live-pages.last.updated.icon.label',
		defaultMessage: 'Last updated',
		description: 'Aria label for the clock icon next to the last updated time in the byline.',
	},
});

export const ByLineLastUpdatedCompact: FC<ByLineLastUpdatedCompactProps> = memo(
	({ version, loading }) => {
		const intl = useIntl();

		return (
			<Flex
				alignItems="center"
				gap="space.075"
				xcss={lastUpdateStyles}
				testId="live-page-last-updated"
			>
				<ClockIcon
					label={intl.formatMessage(i18n.lastUpdateIconLabel)}
					LEGACY_size="small"
					color={token('color.text.subtle', N500)}
					testId="last-updated-icon"
				/>
				{loading && !version.friendlyWhen ? (
					<Flex xcss={loadingStyle} alignItems="center" justifyContent="center">
						<div />
					</Flex>
				) : (
					version?.friendlyWhen
				)}
			</Flex>
		);
	},
);
