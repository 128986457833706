import { LoadableAfterPaint } from '@confluence/loadable';

import type { FullPageEditorPresetAPI } from './ReadTime';

type SendContentFeaturesProps = {
	contentId: string;
	editorApi?: FullPageEditorPresetAPI | null;
	isInEditor?: boolean;
};

export const ReadTime = LoadableAfterPaint<SendContentFeaturesProps>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ReadTime" */ './ReadTime')).ReadTimeLoader,
});

export { preloadReadTime } from './preloadReadTime';
