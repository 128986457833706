import type { FC, KeyboardEvent } from 'react';
import React, { Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled, css } from '@compiled/react';

import Button from '@atlaskit/button/custom-theme-button';
import { token } from '@atlaskit/tokens';
import { N500 } from '@atlaskit/theme/colors';
import AppsIcon from '@atlaskit/icon/core/apps';

const i18n = defineMessages({
	showMore: {
		id: 'content-header.by-line.apps-show-more',
		defaultMessage: 'Show more',
		description: 'The link to show more apps',
	},
	showLess: {
		id: 'content-header.by-line.apps.show-less',
		defaultMessage: 'Show less',
		description: 'The link to show less apps',
	},
});

// eslint-disable-next-line @compiled/no-exported-css, @compiled/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
export const dot = css`
	content: '•';
	display: inline-block;
	color: ${token('color.text.subtle', 'grey')};
	padding: 0 ${token('space.100', '8px')} 0 0;
	text-decoration: none;
	/* for IE <= 11 */
`;

// eslint-disable-next-line @compiled/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const ShowMoreLess = styled.span`
	display: inline-block;
	color: ${token('color.text.subtle', N500)};
	cursor: pointer;

	&::before {
		${dot};
	}
`;

/* eslint-disable @atlaskit/ui-styling-standard/no-important-styles */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const StyledButton = styled(Button)({
	//eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		color: `${token('color.text.subtle', N500)} !important`,
		fontWeight: `${token('font.weight.regular', '400')} !important`,
	},
});
/* eslint-enable @atlaskit/ui-styling-standard/no-important-styles */

export type ByLineAppsShowMoreLessToggleProps = {
	showMore: boolean;
	showButtonDesign: boolean; // Button design is shown in live pages
	setShowAll: (showAll: boolean) => void;
};

export const ByLineAppsShowMoreLessToggle: FC<ByLineAppsShowMoreLessToggleProps> = ({
	showMore = false,
	showButtonDesign = false,
	setShowAll,
}) => {
	const intl = useIntl();

	const textKey = showMore ? i18n.showMore : i18n.showLess;
	const testIdText = showMore ? 'more' : 'less';

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			setShowAll(showMore);
			setTimeout(() => {
				// Using a setTimeout since state change/re-render is interfering with timing of things and timeout will help force
				// the focus to happen after the re-render https://salesforce.stackexchange.com/a/366973
				// Using elementId instead of ref since ref isn't getting set in time
				document.getElementById('byline-apps-show-more-less-toggle')?.focus();
			}, 0);
		}
	};

	return (
		<Fragment>
			{showButtonDesign ? (
				<StyledButton
					iconBefore={<AppsIcon label="" />}
					appearance="subtle"
					testId={`live-pages-byline-show-${testIdText}`}
					onClick={() => setShowAll(showMore)}
				>
					{intl.formatMessage(textKey)}
				</StyledButton>
			) : (
				<ShowMoreLess
					role="button"
					onClick={() => setShowAll(showMore)}
					data-testid={`byline-show-${testIdText}`}
					tabIndex={0}
					onKeyDown={handleKeyDown}
					id="byline-apps-show-more-less-toggle"
				>
					{intl.formatMessage(textKey)}
				</ShowMoreLess>
			)}
		</Fragment>
	);
};
